<template>
  <div class="app-container device-list-comp">
    <div class="filter-line"></div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:templateNameSlot="row">
          <div>
            {{ row.datas.templateName || "-" }}
          </div>
          <div style="font-size: 12px">
            {{ row.datas.templateCode }}
          </div>
        </template>
      </finalTable>
    </div>

    <!-- 添加设备对话框 -->
    <el-dialog
      :visible.sync="device.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ device.title }}
      </template>
      <el-form label-position="top" :model="device.form" ref="deviceForm">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.applet')" prop="applet">
              <el-input
                v-model="device.form.applet"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.appletCode')" prop="deviceName">
              <el-input
                v-model="device.form.appletCode"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.appId')" prop="description">
              <el-input
                v-model="device.form.appId"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.appSecret')" prop="deviceCode">
              <el-input
                v-model="device.form.appSecret"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.mchId')" prop="description">
              <el-input
                v-model="device.form.mchId"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.appletKey')" prop="deviceCode">
              <el-input
                v-model="device.form.appletKey"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.type')" prop="description">
              <el-select v-model="device.form.type" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.typeValue"
                  :label="item.label"
                  :value="item.typeValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('wxManage.tokenChange')" prop="deviceCode">
              <el-select v-model="device.form.tokenChange" placeholder="请选择">
                <el-option
                  v-for="item in tokenChanges"
                  :key="item.ChangeValue"
                  :label="item.label"
                  :value="item.ChangeValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleDeviceSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 保存成功弹框 -->
    <el-dialog
      :visible.sync="successObj.successDialogShow"
      :before-close="
        () => {
          successObj.successDialogShow = false;
        }
      "
      width="728px"
      :close-on-click-modal="false"
    >
      <div class="success-title-line">
        <i class="el-icon-success"></i>
        {{ `成功添加设备 ${successObj.deviceName}，下一步您可以：` }}
      </div>
      <div slot="footer" class="success-footer">
        <el-button type="primary" @click="jumpHanlder('deviceDetail')"
          >查看设备详情</el-button
        >
        <el-button @click="jumpHanlder('deviceNew')">继续添加设备</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  batchDeleteDevice,
  exportDevice,
} from "@/api/ruge/vlink/device/device";
import {
  findSmsSendLog,
  editAppLet,
  deleteAppLet,
  updateEnableStatus,
  tenantGetTemplateList,
} from "@/api/ruge/tenant/tenant.js";
import { getProductList } from "@/api/ruge/vlink/product/product";

export default {
  components: {
    finalTable,
  },
  data() {
    return {
      options: [
        {
          typeValue: "1",
          label: "小程序",
        },
        {
          typeValue: "2",
          label: "公众号",
        },
      ],
      typeValue: "",
      tokenChanges: [
        {
          ChangeValue: "true",
          label: "true",
        },
        {
          ChangeValue: "false",
          label: "false",
        },
      ],
      ChangeValue: "",
      successObj: {
        successDialogShow: false,
        deviceName: "",
        productKey: "",
      },
      loadingFlag: false,
      productList: [],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "phone",
            label: this.$t("sms.phone"),
            width: "",
          },
          {
            prop: "comment",
            label: this.$t("sms.comment"),
            width: "",
          },
          {
            prop: "templateName",
            label: "模板名称",
            width: "",
          },
          {
            prop: "sendStatus",
            label: this.$t("sms.sendStatus"),
            width: "",
          },
          {
            prop: "sendTime",
            label: this.$t("sms.sendTime"),
            width: "",
          },
        ],
        tableData: [],
        searchLineConfig: {
          phone: {
            type: "input",
            label: "手机号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入手机号",
            prefixIcon: "el-icon-search",
          },
          templateName: {
            type: "selector",
            label: "模板名称",
            value: "",
            placeholder: "请选择模板名称",
            actionType: "goSearch",
            filterable: true,
            optionList: [],
          },
        },
        // 表格内容配置
        detailConfig: {
          templateName: {
            type: "slot",
            slotName: "templateNameSlot",
          },
          deviceName: {
            type: "aLink",
            actionType: "aLinkClick",
            targetType: "device",
          },
          productKey: {
            type: "otherParam",
            paramName: "productName",
          },
          nodeType: {
            type: "enumerationColumn",
            emuList: {
              DIRECTDEVICE: "直连设备",
              GATEWAY: "网关设备",
              SUBDEVICE: "网关子设备",
            },
          },
          state: {
            type: "switch",
            actionType: "switchEvent",
          },
          type: {
            type: "tags",
            1: {
              type: "1",
              label: "小程序",
            },
            2: {
              type: "2",
              label: "公众号",
            },
          },
          sendTime: {
            type: "dateFormat",
          },
          activationTime: {
            type: "dateFormat",
          },
          lastOnlineTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          state: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          phone: null,
          current: 1,
          rowCount: 10,
          deviceName: null,
          deviceStatus: null,
          nodeType: null,
          productKey: null,
          registerStartTime: null,
          registerEndTime: null,
          activeStartTime: null,
          activeEndTime: null,
          lastStartTime: null,
          lastEndTime: null,
        },
        multipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        form: {
          id: null,
          applet: null,
          appletCode: null,
          appId: null,
          appSecret: null,
          mchId: null,
          appletKey: null,
          type: null,
          tokenChange: null,
        },
        formRules: {
          productId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          description: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          deviceName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 4,
              message: this.$t("vlink.device.deviceNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-@#()]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    // 如果从设备详情返回，则需要回显上次的查询条件
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("device_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.device.listQuery = { ...this.device.listQuery, ...tempObj };
        Object.keys(this.device.listQuery).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.device.listQuery[item];
          }
        });
        // 处理高级查询中的taglist和创建时间
        const registerTemp = [];
        tempObj.registerStartTime &&
          registerTemp.push(tempObj.registerStartTime);
        tempObj.registerEndTime && registerTemp.push(tempObj.registerEndTime);
        const ActiveTemp = [];
        tempObj.activeStartTime && ActiveTemp.push(tempObj.activeStartTime);
        tempObj.activeEndTime && ActiveTemp.push(tempObj.activeEndTime);
        const lastTemp = [];
        tempObj.lastStartTime || null,
          tempObj.lastEndTime || null,
          tempObj.lastStartTime && lastTemp.push(tempObj.lastStartTime);
        tempObj.lastEndTime && lastTemp.push(tempObj.lastEndTime);
        this.dataset.advanceFilterConfig.creationDate.value = registerTemp;
        this.dataset.advanceFilterConfig.activationTime.value = ActiveTemp;
        this.dataset.advanceFilterConfig.lastOnlineTime.value = lastTemp;
        if (tempObj.tagList && tempObj.tagList.length > 0) {
          this.dataset.advanceFilterConfig.tagList.value =
            tempObj.tagList.split(",");
        }
        // 处理分页数据
        this.dataset.pageVO.current = tempObj.current;
        this.dataset.pageVO.rowCount = tempObj.rowCount;
      }
    } else {
      // // 默认查询启用状态数据
      // this.dataset.searchLineConfig.state.value = "0";
      // this.device.listQuery.enableStatus = "0";
    }
    // console.log("this.$route.params", this.$route.params);
    this.getDevicePage();
    this.getProductList();
  },
  mounted() {
    // 从总览跳转过来
    const { type } = this.$route.query;
    if (type === "device" || type === "gateway") {
      this.device.title = this.$t("vlink.device.add");
      this.device.addDialogVisible = true;
      if (type === "gateway") {
        this.$nextTick(() => {
          this.device.form.productId = "GraZGrYZZW8";
        });
      }
    }
    this.initTemplateList();
  },
  methods: {
    initTemplateList() {
      tenantGetTemplateList({
        tenantId: this.$store.getters.currentTenant,
      }).then((res) => {
        this.dataset.searchLineConfig.templateName.optionList = [
          {
            label: "其他",
            value: -1,
          },
        ].concat(
          res.map((item) => {
            return {
              label: item.templateName,
              value: item.templateCode,
            };
          })
        );
      });
    },
    jumpHanlder(type) {
      switch (type) {
        case "deviceDetail":
          this.$router.push({
            path: "/iot/device/detail",
            query: {
              productKey: this.successObj.productKey,
              deviceName: this.successObj.deviceName,
            },
          });
          break;
        case "deviceNew":
          this.successObj.successDialogShow = false;
          this.createDevice();
          break;
      }
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findDevicePage();
    },
    exportHandler() {
      delete this.device.listQuery.activationTime;
      delete this.device.listQuery.creationDate;
      delete this.device.listQuery.lastOnlineTime;
      exportDevice(this.device.listQuery).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "设备列表",
          taskStatus: 0,
          rootPath: "thingsPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    // 取消保存
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
    },
    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          editAppLet(this.device.form)
            .then((res) => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              this.cancelDeviceSave();
              this.findDevicePage();
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            });
        }
      });
    },
    //初始化产品列表
    getProductList() {
      getProductList()
        .then((res) => {
          this.productList = res.filter(
            (item) => item.nodeType !== "SUBDEVICE"
          );
          this.dataset.searchLineConfig.productKey.optionList = res.map(
            (item) => {
              return {
                value: item.productKey,
                label: item.productName,
              };
            }
          );
          const { type } = this.$route.query;
          if (type === "gateway") {
            this.device.form.productId = 1;
          }
          console.log("this.1", this.productList);
        })
        .finally(() => {
          const { defaultProduct } = this.$route.query;
          this.device.form.productId = defaultProduct;
        });
    },
    // 批量删除
    batchDelete() {
      if (this.device.multipleSelection.length < 1) return;
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchDeleteDevice({
            deviceIdList: this.device.multipleSelection,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // 处理时间参数
        if (datas.params.tagList && datas.params.tagList.length > 0)
          datas.params.tagList = datas.params.tagList.join(",");
        this.device.listQuery.templateCode = datas.params.templateName;
        delete datas.params.templateName;
        this.dealTime(datas);
        this.device.listQuery = { ...this.device.listQuery, ...datas.params };
        this.findDevicePage();
      } else if (datas.type === "paginationChange") {
        this.device.listQuery.current = datas.params.current.page;
        this.device.listQuery.rowCount = datas.params.current.limit;
        this.getDevicePage();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editDevice(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.id);
            break;
        }
      }
    },
    editDevice(row) {
      console.log("tanghong", row);
      this.currentState = "edit";
      this.device.title = this.$t("vlink.device.edit");
      this.device.form.applet = row.applet;
      this.device.form.appletCode = row.appletCode;
      this.device.form.appId = row.appId;
      this.device.form.appSecret = row.appSecret;
      this.device.form.mchId = row.mchId;
      this.device.form.appletKey = row.appletKey;
      this.device.form.type = row.type;
      this.device.form.tokenChange = row.tokenChange;
      this.device.addDialogVisible = true;
      this.device.form.id = row.id;
    },
    dealTime(datas) {
      // 注册时间
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.device.listQuery.registerStartTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.device.listQuery.registerEndTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        this.device.listQuery.registerStartTime = "";
        this.device.listQuery.registerEndTime = "";
      }
      // 激活时间
      if (
        datas.params.activationTime &&
        datas.params.activationTime.length > 0
      ) {
        this.device.listQuery.activeStartTime = datas.params.activationTime[0]
          ? new Date(datas.params.activationTime[0]).getTime()
          : null;
        this.device.listQuery.activeEndTime = datas.params.activationTime[1]
          ? new Date(datas.params.activationTime[1]).getTime()
          : null;
        delete datas.params.activationTime;
      } else {
        this.device.listQuery.activeStartTime = "";
        this.device.listQuery.activeEndTime = "";
      }
      // 最后上线时间
      if (
        datas.params.lastOnlineTime &&
        datas.params.lastOnlineTime.length > 0
      ) {
        this.device.listQuery.lastStartTime = datas.params.lastOnlineTime[0]
          ? new Date(datas.params.lastOnlineTime[0]).getTime()
          : null;
        this.device.listQuery.lastEndTime = datas.params.lastOnlineTime[1]
          ? new Date(datas.params.lastOnlineTime[1]).getTime()
          : null;
        delete datas.params.lastOnlineTime;
      } else {
        this.device.listQuery.lastStartTime = "";
        this.device.listQuery.lastEndTime = "";
      }
      return datas;
    },
    createDevice() {
      this.device.title = this.$t("wxManage.addWX");
      this.device.form = {
        state: "ENABLE",
        isDelete: "0",
      };
      this.device.addDialogVisible = true;
    },
    updateEnableStatus(row) {
      updateEnableStatus(row)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findDevicePage();
        })
        .catch((error) => {
          this.findDevicePage();
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    deleteDevice(id) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteAppLet({ id }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 查看详情
    viewDeviceDetail(row) {
      this.$router.push({
        path: "/iot/device/detail",
        query: {
          productKey: row.productKey,
          deviceName: row.deviceName,
        },
      });
    },
    findDevicePage() {
      this.device.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getDevicePage();
    },
    getDevicePage() {
      let param = this.device.listQuery;
      // 记录设备列表查询条件
      sessionStorage.setItem("device_list_query_params", JSON.stringify(param));
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.loadingFlag = true;
      findSmsSendLog(param)
        .then((response) => {
          let resultList = response.rows;
          this.dataset.tableData = resultList;
          this.dataset.pageVO.total = response.total;
          // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
          this.loadingFlag = false;
        })
        .catch((error) => {
          console.log("获取设备列表失败：" + error);
          this.loadingFlag = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.device-list-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .success-title-line {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #303133;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .el-icon-success {
      color: #67c23a;
      font-size: 24px;
      margin-right: 15px;
    }
  }
  .success-footer {
    text-align: center;
  }
}
</style>